<template>
  <div>
    <custom-modal :visible="isRefundClicked" :closable="false" :footer="null" class="upgrade-modal-container">
      <template #modal-title>
        <div class="modal-header flex items-center justify-between">
          <div class="flex items-center modal-title">
            <img :src=succes_icon alt="" :style="{ width: '16px', height: '16px', marginRight: '16px' }">
            <p>Submitted successfully</p>
          </div>
          <img :src="close_icon" :style="{ width: '20px', height: '20px', cursor: 'pointer' }"
            @click="handleCloseModal" />
        </div>
      </template>
      <template #modal-content>
        <div class="modal-body">
          We will then review your request within 7 days and contact you via email.
        </div>
      </template>
      <template #operate-button>
        <div class="flex justify-center pt-6">
        </div>
      </template>
    </custom-modal>
  </div>
</template>

<script setup>
import close_icon from '@/assets/common-icons/icon_close.svg';
import succes_icon from '@/assets/premium/success.svg';
import CustomModal from '@/components/boolv-ui/custom-modal/custom-modal.vue'
import { usePremiumStore } from '@/store/premium.js'
import { storeToRefs } from 'pinia'
import { ref, watchEffect, onMounted } from 'vue';
const premium = usePremiumStore();
const { isRefundClicked } = storeToRefs(premium);
const isModalVisible = ref(false);
const halfHeight = ref(0);
onMounted(async () => {
  const screenHeight = window.innerHeight;
  halfHeight.value = screenHeight / 2;
})
watchEffect(() => {

  if (isRefundClicked.value) {
    isModalVisible.value = true
  }
})
const handleCloseModal = () => {
  isRefundClicked.value = false;
  isModalVisible.value = false;
}
</script>

<style lang="less" scoped>
.modal-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1F2329;
}

.modal-body {
  padding: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #060606;
  margin-top: 12px;
  margin-right: 32px;
  margin-left: 32px;
}

:global(.upgrade-modal-container .ant-modal-content) {
  border-radius: 8px;
}

:global(.upgrade-modal-container .ant-modal-header) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>