<template>
  <div class="refund-container">
    <refund-modal />
    <img class="premiump-bg" :src="premiumBg" alt="">
    <p class="form-title">Refund Policy</p>
    <div class="refund-details-box">

      <p class="refund-details-header">Refund Policy</p>
      <div class="refund-details-body">
        <p>The 7 Days Money Back Guarantee applies if ... </p>
        <p style="margin-top:35px;">1. you have purchased a subscription for the first time,</p>
        <p>2. the subscription was purchased within the last 7 days,</p>
        <p>3. you have processed a total of less than 100 subscription credits, and</p>
        <p>4. you have not made use of the 7 Days Money Back Guarantee before.</p>

        <p style="margin-top:35px;">To place a refund request, you would have to fill out this form.</p>
        <p>We will then review your request within 7 days and contact you via email.</p>
      </div>
      <div class="refund-form">
        <a-form>
          <a-form-item class="refund-form-title">
            <p>Reason for Refund</p>
          </a-form-item>
          <a-form-item>
            <a-textarea class="refund-form-textarea " v-model:value="reason" :style="isReasonNull ? errorStyle : ''">
            </a-textarea>
          </a-form-item>
          <a-form-item class="refund-form-title">
            <p>Quality Issues</p>
          </a-form-item>
          <a-form-item>
            <a-textarea class="refund-form-textarea" v-model:value="issue" :style="isIssueNull ? errorStyle : ''">
            </a-textarea>
          </a-form-item>
        </a-form>
      </div>
      <button class="refund-details-footer" @click="onFinish" :disabled="disabled"
        :style="disabled && { 'pointer-events': 'none' }">
        <LoadingOutlined :style="loadingStyle" v-if="disabled" />
        <div>Submit</div>
      </button>
    </div>




  </div>
</template>

<script setup>
import premiumBg from '@/assets/premium/premium_bg.webp'
import RefundModal from './refund-modal.vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import { ref, reactive, onMounted } from 'vue';
import { usePremiumStore } from '@/store/premium.js';
import { storeToRefs } from 'pinia';
import {
  userRefund,
} from "@/api/premium/index";
import { eventTracking } from '../../utils/eventTracking';
const premium = usePremiumStore();
const { isRefundClicked } = storeToRefs(premium);
const reason = ref('');
const issue = ref('');
const isReasonNull = ref(false);
const isIssueNull = ref(false);
const disabled = ref(false);
const loadingStyle = reactive({
  position: 'absolute',
  left: '270px',
})
const errorStyle = reactive({
  border: '1px solid red',
  'box-shadow': 'none',
});
import {
  MessageSuccess,
  MessageError,
  destroy,
} from "@/components/boolv-ui/Message/index.js";
onMounted(() => {
  eventTracking('booltool_refund_view');
})
const onFinish = async () => {
  disabled.value = true;
  console.log(disabled.value)
  // 校验
  console.log('reason.value, issue.value-------->', reason.value, issue.value);
  if (reason.value === '' && issue.value === '') {
    isReasonNull.value = true;
    isIssueNull.value = true;
    MessageError("Both reason and issue cannot be empty")
    setTimeout(() => {
      destroy();
    }, 2000);
  }
  else if (reason.value === '' && issue.value !== '') {
    isReasonNull.value = true;
    MessageError("Reason cannot be empty");
    setTimeout(() => {
      destroy();
    }, 2000);
  }
  else if (reason.value !== '' && issue.value === '') {
    isIssueNull.value = true;
    MessageError("Both reason cannot be empty");
    setTimeout(() => {
      destroy();
    }, 2000);
  }
  else {
    const payload = {
      customerQualityIssue: issue.value,
      customerReason: reason.value,
    }
    await userRefund(payload).then(res => {
      const { code } = res;
      if (code === 0) {
        isRefundClicked.value = true;
      }
    });
    isReasonNull.value = false;
    isIssueNull.value = false;
  }
  eventTracking('booltool_refund_submit');
  disabled.value = false;

}

</script>

<style lang="less" scoped>
.refund-container {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  position: relative;
  margin-bottom: 531px;

  .premiump-bg {
    position: absolute;
    height: 650px;
    width: 100%;
    overflow: hidden;
    z-index: -1;
  }

  .form-title {
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 81px;
    margin-bottom: 71px;
  }

  .refund-details-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 796px;
    border: 1px solid #DEE0E3;
    box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background: #FFFFFF;
    padding: 46px 60px 56px 60px;
  }

  .refund-details-header {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #060606;
  }

  .refund-details-body {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #060606;
  }

  .refund-form {
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .refund-form-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #060606;
      text-align: start;
    }

    .refund-form-textarea {
      width: 100%;
      height: 172px;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      color: #060606;
      resize: none;
      border: 1px solid #D0D0D0;
      border-radius: 6px;
    }

    .refund-form-textarea::-webkit-scrollbar {
      width: 16px;
      height: 59px;
    }

    .refund-form-textarea::-webkit-scrollbar-thumb {
      border-radius: 20px;
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
      border: 5px solid rgba(0, 0, 0, 0);
      box-shadow: 10px 0 0 #c3c3c3 inset;
    }

    .refund-form-textarea::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  .refund-details-footer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 24px;


    div {
      width: 255px;
      background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);
      border-radius: 36px;
      cursor: pointer;

      &:hover {
        background: linear-gradient(289deg, #6F46F4 5.38%, #957AEC 99.95%);
      }
    }
  }
}

:global(.refund-form .ant-form) {
  width: 100%;
}
</style>